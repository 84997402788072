import React from "react"

const SearchIcon = ({
  width = 20,
  height = 20,
  viewBox = "0 0 20 20",
  color = "#C2C3C5",
}) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.5 17.5L12.7426 12.7426M12.7426 12.7426C13.8284 11.6569 14.5 10.1569 14.5 8.5C14.5 5.18629 11.8137 2.5 8.5 2.5C5.18629 2.5 2.5 5.18629 2.5 8.5C2.5 11.8137 5.18629 14.5 8.5 14.5C10.1569 14.5 11.6569 13.8284 12.7426 12.7426Z"
      stroke={color}
      strokeWidth="1.3"
      strokeLinejoin="round"
    />
  </svg>
)

export default SearchIcon
