import { Layout, SEO } from "components"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import * as React from "react"
import "./blog.scss"

import SmartLink from "components/SmartLink/SmartLink"
import { formatDate } from "utils/dateFunctions"
import SearchIcon from "components/ReactSvgIcons/SearchIcon"

interface IBlogListPageProps {
  data: any
}
const BlogListPage: React.FunctionComponent<IBlogListPageProps> = ({
  data,
}) => {
  if (!data) {
    return null
  }
  const [searchValue, setSearchValue] = React.useState("")
  const blogs = data.allPrismicBlogPage.nodes
  const [first, ...rest] = [...(blogs ?? [])]
  const [selectedFilter, setSetSelectedFilter] = React.useState("All posts")
  const filterList: any[] = [
    "All posts",
    ...new Set(
      rest
        ?.map?.(
          (item: {
            data?: {
              body?: {
                primary?: { blog_category?: string | null | undefined }
              }[]
            }
          }) => item?.data?.body?.[0]?.primary?.blog_category
        )
        .filter(Boolean)
    ),
  ]

  const filteredBlogs = React.useMemo(() => {
    return rest.filter(blog => {
      const title =
        blog?.data?.body?.[0]?.primary?.blog_title?.text?.toLowerCase()
      const content =
        blog?.data?.body?.[0]?.primary?.blog_content?.text?.toLowerCase()
      const category =
        blog?.data?.body?.[0]?.primary?.blog_category?.toLowerCase()

      // Search filter: Check if title, content, or category includes the search term
      const matchesSearch =
        title?.includes(searchValue.toLowerCase()) ||
        content?.includes(searchValue.toLowerCase()) ||
        category?.includes(searchValue.toLowerCase())

      // Category filter: If "All posts" is selected, include all categories, otherwise filter by selected category
      const matchesCategory =
        selectedFilter === "All posts" ||
        category === selectedFilter.toLowerCase()

      // Both conditions must be met
      return matchesSearch && matchesCategory
    })
  }, [searchValue, selectedFilter, rest]) // Dependencies ensure re-computation when needed

  return (
    <Layout>
      <SEO
        title="Teamble Blog"
        description="Latest news, content, and science-backed discussions on workplace culture. Teamble is advised by the global thought leader in organizational psychology."
      />

      <div className="BlogSection">
        <div className="banner-section">
          <div className="title">
            Improve your workplace with{" "}
            <span className="highlight">teamble blog</span>
          </div>
          <div className="bannerImage">
            <div className="content">
              {typeof first?.data?.published_at === "string" && (
                <div className="date">
                  {formatDate(first?.data?.published_at)}
                </div>
              )}
              {first?.data?.body?.[0]?.primary?.blog_title?.text && (
                <div className="card-title">
                  {first?.data?.body?.[0]?.primary?.blog_title?.text ?? ""}
                </div>
              )}
              {first?.data?.body?.[0]?.primary?.blog_content?.text && (
                <div className="subtitle">
                  {first?.data?.body?.[0]?.primary?.blog_content?.text}
                </div>
              )}
              <SmartLink className="link" url={`${first?.uid}`}>
                Read whole story
              </SmartLink>
            </div>
            <GatsbyImage
              className="image"
              alt={first?.data?.body?.[0]?.primary?.feature_image?.alt}
              image={
                first?.data?.body?.[0]?.primary?.feature_image?.gatsbyImageData
              }
              objectFit="cover"
            />
          </div>
          <div className="filter-container">
            <div className="search">
              <input
                value={searchValue}
                onChange={e => setSearchValue(e.target.value ?? "")}
                placeholder="Search for articles..."
              />
              <SearchIcon />
            </div>
            <div className="filters">
              {filterList.map((item: any) => (
                <div
                  onClick={() => {
                    setSetSelectedFilter(item)
                  }}
                  className={`items ${
                    selectedFilter === item && "selected-filter"
                  }`}
                >
                  {item}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="case-studies-list">
          <div className="no-blogs">
            {filteredBlogs.length === 0 && (
              <div>
                ✨ No blogs available. Check back later or explore other
                categories! 👍
              </div>
            )}
          </div>
          {filteredBlogs?.map((item: any, index: number) => (
            <div key={index} className="card">
              <GatsbyImage
                className="image"
                alt={item?.data?.body?.[0]?.primary?.feature_image?.alt}
                image={
                  item?.data?.body?.[0]?.primary?.feature_image?.gatsbyImageData
                }
                objectFit="cover"
              />
              <div className="content">
                <div>
                  {typeof item?.data?.published_at === "string" && (
                    <div className="date">
                      {formatDate(item?.data?.published_at)}
                    </div>
                  )}
                  {item?.data?.body?.[0]?.primary?.blog_title?.text && (
                    <div className="card-title">
                      {item?.data?.body?.[0]?.primary?.blog_title?.text ?? ""}
                    </div>
                  )}
                  {item?.data?.body?.[0]?.primary?.blog_content?.text && (
                    <div className="subtitle">
                      {item?.data?.body?.[0]?.primary?.blog_content?.text}
                    </div>
                  )}
                </div>
                <SmartLink url={`${item?.uid}`}>
                  <div className="link">Read whole story</div>
                </SmartLink>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    allPrismicBlogPage(sort: { fields: data___published_at, order: DESC }) {
      nodes {
        uid
        data {
          body {
            ... on PrismicBlogPageDataBodyBlog {
              id
              primary {
                feature_image {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                }
                blog_title {
                  text
                  richText
                }
                blog_content {
                  text
                  richText
                }
                blog_category
                author_name
                author_description
              }
              slice_type
            }
          }
          published_at
        }
      }
    }
  }
`

export default BlogListPage
